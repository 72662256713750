/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    right: 36px;
    top: 13px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #000000;
    border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 40px;
    width: 40px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #000000;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #EDEDED;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    display: flex;
    justify-content: center;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #000000;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;

}

/* Individual item */
.bm-item {
    display: inline-block;
    padding-bottom: 15px;
    padding: 20px;

}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 600pxnpm;
}

.menu-item {
    font-family: inherit;
    font-size: x-large;
    padding: 20px;
}